import React from "react";
import Header from "../Components/Header";
import "../Styles/Contact.scss";
import ContactComponent from "../Components/Contact";
import AboutComponent from "../Components/AboutComponent";

const About = () => {
  return (
    <AboutComponent></AboutComponent>
  );
};

export default About;
