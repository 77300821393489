import React from "react";
import "../Styles/Ecommerce.scss";
import Etsy from "../Styles/etsy.svg"
import Amazon from "../Styles/logo-amazon.svg"
import Walmart from "../Styles/walmart.svg"
import Ebay from "../Styles/ebay.svg"
import Woo from "../Styles/woocommerce-1.svg"
import Shopify from "../Styles/shopify-2.svg"


const Ecommerce = () => {
  return (
  <div className="ecommerce-container">
    <h1>Integrated to E-Commerce Platforms</h1>
    <div className="logos">
        <img src={Woo} alt="WooCommerce" />
        <img src={Shopify} alt="Shopify" />
        <img src={Amazon} alt="Amazon" />
        <img src={Etsy} alt="Etsy" />
        <img src={Ebay} alt="Ebay" />
        <img src={Walmart} alt="Walmart" />
    </div>

  </div>
  );
};

export default Ecommerce;
